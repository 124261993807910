<template>
  <v-layout>
    <SystemsettingsAppBar />

    <v-container fluid class="px-0 pt-5">
      <v-col cols="11" xl="8" lg="9" md="11" class="mx-auto px-0 pt-0">
        <v-row dense class="align-center justify-center">
          <v-card tile outlined class="flex-grow-1">
            <v-col cols="12">
              <v-card-title class="px-2">
                <v-row dense align="center">
                  <v-col cols="12">
                    <v-toolbar-title class="d-flex flex-shrink-1">
                      Mitarbeiter
                    </v-toolbar-title>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider inset></v-divider>
              <v-card-text class="px-2 subtitle-2">
                <v-row dense class="align-center justify-center">
                  <v-col cols="12">
                    <v-card tile outlined class="flex-grow-1">
                      <v-col cols="12">
                        <v-card-title class="px-2">
                          <v-row dense>
                            <v-col cols="auto">
                              <v-text-field
                                dense
                                hide-details
                                v-model="filter.search"
                                prepend-icon="mdi-magnify"
                                clearable
                                placeholder="Suche"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <v-divider inset></v-divider>
                        <v-card-text class="px-2 subtitle-2">
                          <v-row dense>
                            <v-col cols="12">
                              <v-data-table
                                v-model="selected"
                                height="calc(48px + (48px * 5))"
                                fixed-header
                                hide-default-footer
                                :items="datasets"
                                item-key="Bearbeiter_ID"
                                :items-per-page="25"
                                :page.sync="page"
                                @page-count="pageCount = $event"
                                :headers="datasetHeaders"
                                :search="filter.search"
                                :single-select="true"
                                show-select
                                @item-selected="assignDataset"
                                mobile-breakpoint="300"
                                style="width: 100%;"
                              >
                                <template #[`item.Name`]="{ item }">
                                  <span class="font-weight-bold"> {{ item.Name }}</span>
                                </template>

                                <template #[`item.aktiv`]="{ item }">
                                  <span v-if="item.aktiv === 0"><v-icon color="red lighten-2">mdi-cancel</v-icon></span>
                                  <span v-if="item.aktiv === 1"><v-icon color="green lighten-2">mdi-check</v-icon></span>
                                </template>
                              </v-data-table>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-divider inset></v-divider>
                        <v-card-actions>
                          <v-row dense align="center">
                            <v-col cols="12">
                              <v-pagination color="grey darken-2" v-model="page" :length="pageCount"></v-pagination>
                            </v-col>
                          </v-row>
                        </v-card-actions>
                      </v-col>
                    </v-card>
                  </v-col>
                  <v-col cols="12" xl="5" lg="6" md="6" sm="8">
                    <v-row>
                      <v-col cols="12" v-if="selected.length == 0">
                        <v-row dense>
                          <v-col cols="12">
                            <span class="font-weight-bold">Name</span>
                            <v-text-field
                              v-model="newDataset.Name"
                              hide-details
                              placeholder="Name"
                              color="primary"
                              outlined
                              clearable
                              :error="checkName"
                              ><template v-slot:append>
                                <v-icon v-if="!checkName && newDataset.Name" color="green lighten-2">mdi-check</v-icon>
                                <v-icon v-else-if="checkName && newDataset.Name" color="red lighten-2">mdi-alert</v-icon>
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <span class="font-weight-bold">Vorname</span>
                            <v-text-field
                              v-model="newDataset.Vorname"
                              hide-details
                              placeholder="Vorname"
                              color="primary"
                              outlined
                              clearable
                              :error="checkVorname"
                              ><template v-slot:append>
                                <v-icon v-if="!checkVorname && newDataset.Vorname" color="green lighten-2">mdi-check</v-icon>
                                <v-icon v-else-if="checkVorname && newDataset.Vorname" color="red lighten-2">mdi-alert</v-icon>
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <span class="font-weight-bold">Abkürzung</span>
                            <v-text-field
                              v-model="newDataset.Abkürzung"
                              hide-details
                              placeholder="Abkürzung"
                              color="primary"
                              outlined
                              clearable
                              :error="checkAbkürzung"
                              ><template v-slot:append>
                                <v-icon v-if="!checkAbkürzung && newDataset.Abkürzung" color="green lighten-2">mdi-check</v-icon>
                                <v-icon v-else-if="checkAbkürzung && newDataset.Abkürzung" color="red lighten-2">mdi-alert</v-icon>
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-checkbox dense hide-details v-model="newDataset.aktiv" :true-value="1" :false-value="0">
                              <template v-slot:label>
                                <span class="text--primary ">aktiver Mitarbeiter </span>
                              </template>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="12" class="pt-4">
                            <v-btn
                              @click="speichern(newDataset)"
                              block
                              color="green lighten-2 grey--text text--lighten-4"
                              :disabled="
                                !newDataset.Name || checkName || !newDataset.Vorname || checkVorname || !newDataset.Abkürzung || checkAbkürzung
                              "
                            >
                              <v-icon>mdi-plus</v-icon>
                              <span class="ml-1">Neu</span>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" v-else>
                        <v-row dense>
                          <v-col cols="12">
                            <span class="font-weight-bold">Name</span>
                            <v-text-field
                              v-model="editableDataset.Name"
                              hide-details
                              placeholder="Name"
                              color="primary"
                              outlined
                              clearable
                              :error="!editableDataset.Name"
                              ><template v-slot:append>
                                <v-icon v-if="editableDataset.Name" color="green lighten-2">mdi-check</v-icon>
                                <v-icon v-else-if="!editableDataset.Name" color="red lighten-2">mdi-alert</v-icon>
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <span class="font-weight-bold">Vorname</span>
                            <v-text-field
                              v-model="editableDataset.Vorname"
                              hide-details
                              placeholder="Vorname"
                              color="primary"
                              outlined
                              clearable
                              :error="!editableDataset.Vorname"
                              ><template v-slot:append>
                                <v-icon v-if="editableDataset.Vorname" color="green lighten-2">mdi-check</v-icon>
                                <v-icon v-else-if="!editableDataset.Vorname" color="red lighten-2">mdi-alert</v-icon>
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <span class="font-weight-bold">Abkürzung</span>
                            <v-text-field
                              v-model="editableDataset.Abkürzung"
                              hide-details
                              placeholder="Abkürzung"
                              color="primary"
                              outlined
                              clearable
                              :error="!editableDataset.Abkürzung"
                              ><template v-slot:append>
                                <v-icon v-if="editableDataset.Abkürzung" color="green lighten-2">mdi-check</v-icon>
                                <v-icon v-else-if="!editableDataset.Abkürzung" color="red lighten-2">mdi-alert</v-icon>
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-checkbox dense hide-details v-model="editableDataset.aktiv" :true-value="1" :false-value="0">
                              <template v-slot:label>
                                <span class="text--primary ">aktiver Mitarbeiter </span>
                              </template>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="12" class="pt-4">
                            <v-btn
                              block
                              color="orange lighten-2 grey--text text--lighten-4"
                              @click="speichern(editableDataset)"
                              :disabled="!editableDataset.Name || !editableDataset.Vorname || !editableDataset.Abkürzung"
                            >
                              <v-icon>mdi-pencil</v-icon>
                              <span class="ml-1">Bearbeiten</span>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-card>
        </v-row>
      </v-col>
    </v-container>
  </v-layout>
</template>

<script>
import SystemsettingsAppBar from "../../../components/appbars/SystemsettingsAppBar.vue";

export default {
  components: {
    SystemsettingsAppBar,
  },

  mounted() {
    this.initialize();
  },

  data() {
    return {
      filter: {
        search: "",
      },
      datasetHeaders: [],
      hiddenColumns: ["Bearbeiter_ID"],
      datasets: [],
      page: 1,
      pageCount: 0,
      selected: [],
      newDataset: {},
      editableDataset: {},
    };
  },

  computed: {
    checkName() {
      const findname = this.datasets.filter((item) => item.Name === this.newDataset.Name || item.Name === this.editableDataset.Name);
      if (findname.length > 0) {
        return true;
      } else return false;
    },
    checkVorname() {
      const findname = this.datasets.filter((item) => item.Vorname === this.newDataset.Vorname || item.Vorname === this.editableDataset.Vorname);
      if (findname.length > 0) {
        return true;
      } else return false;
    },
    checkAbkürzung() {
      const findname = this.datasets.filter(
        (item) => item.Abkürzung === this.newDataset.Abkürzung || item.Abkürzung === this.editableDataset.Abkürzung
      );
      if (findname.length > 0) {
        return true;
      } else return false;
    },
  },

  methods: {
    async initialize() {
      const response = await fetch(`/api/settings/systemsettings/coworkers`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      const filterableHeaders = [
        true, //Name
        true, //Vorname
        true, //Abkürzung
        false, //aktiv
      ];
      const alignmentHeaders = ["start", "start", "start", "center"];
      const dividerHeaders = [true, true, true, false];

      this.datasetHeaders = Object.keys(this.datasets[0])
        .filter((it) => this.hiddenColumns.indexOf(it) === -1)
        .map((it, i) => {
          return {
            text: it,
            value: it,
            sortable: false,
            filterable: filterableHeaders[i],
            align: alignmentHeaders[i],
            divider: dividerHeaders[i],
            class: "text--primary text-uppercase",
          };
        });
    },

    assignDataset({ item, value }) {
      if (value == true) {
        this.editableDataset = Object.assign({}, item);
        this.newDataset = {};
      } else this.editableDataset = {};
    },

    async speichern(dataset) {
      if (!dataset.Bearbeiter_ID) {
        const datasetToInsert = {};

        if (Object.keys(datasetToInsert).length === 0) {
          Object.assign(datasetToInsert, {
            Name: dataset.Name,
            Vorname: dataset.Vorname,
            Abkürzung: dataset.Abkürzung,
            aktiv: dataset.aktiv,
          });
        }

        await fetch("/api/settings/systemsettings/coworker/dataset/insert", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(datasetToInsert),
        });

        this.$root.actionSnackbar.show({
          dataset: "Mitarbeiter",
          action: "erstellt",
        });
      } else if (dataset.Bearbeiter_ID) {
        const datasetToUpdate = {};

        if (Object.keys(datasetToUpdate).length === 0) {
          Object.assign(datasetToUpdate, {
            Bearbeiter_ID: dataset.Bearbeiter_ID,
            Name: dataset.Name,
            Vorname: dataset.Vorname,
            Abkürzung: dataset.Abkürzung,
            aktiv: dataset.aktiv,
          });
        }

        await fetch("/api/settings/systemsettings/coworker/dataset/update", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(datasetToUpdate),
        });

        this.$root.actionSnackbar.show({
          dataset: "Mitarbeiter",
          action: "bearbeitet",
        });
      }

      this.editableDataset = {};
      this.newDataset = {};
      this.selected = [];
      this.initialize();
    },
  },
};
</script>
